import {useEffect, useState, useRef, useCallback} from 'react';
import {cva} from 'class-variance-authority';

import {useTranslations} from '@/hooks/useTranslations';
import {twMerge} from '@/stylesheets/twMerge';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import useReducedMotion from '@/hooks/useReducedMotion';
import {replacePlaceholders} from '@/utils/utils';

import DirectionalControls from './components/DirectionalControls';
import type {TestimonialsProps} from './types';
import Testimonial from './Testimonial';

const styles = cva(
  'flex relative w-full overflow-x-clip sm:px-margin xl:px-auto-xl',
  {
    variants: {
      mode: {
        light: 'text-black',
        dark: 'text-white',
      },
      center: {
        true: 'text-center',
      },
    },
  },
);

const sharedPictureStyles = 'w-[calc(100%-16px)] sm:w-full';

const pictureStyles = cva(
  [
    sharedPictureStyles,
    'transition-[transform,opacity] duration-[450ms] ease-in-out',
  ],
  {
    variants: {
      isLooping: {
        true: 'will-change-[transform,opacity]',
      },
      isPlaying: {
        true: 'will-change-[transform,opacity]',
      },
      isPrevQueue: {
        true: 'translate-x-[calc(-200%-(var(--gutter)*2))]',
      },
      isPrev: {
        true: 'translate-x-[calc(-100%-var(--gutter))]',
      },
      isActive: {
        true: 'opacity-100',
        false: 'opacity-0',
      },
      isNext: {
        true: 'opacity-30 translate-x-[calc(100%+var(--gutter))]',
      },
      isNextQueue: {
        true: 'translate-x-[calc(200%+(var(--gutter)*2))]',
      },
    },
    defaultVariants: {
      isActive: false,
    },
  },
);

/**
 * @todo Find better way to override `.container` styles on `<Grid>`
 * than using `!mx-0` etc util classes
 */
const testimonialStyles = cva(
  'shrink-0 !mx-0 !w-full max-sm:px-[var(--margin)]',
  {
    variants: {
      isActive: {
        true: 'z-10',
      },
    },
  },
);

/**
 * In order to smoothly cycle through any carousel, we need to ensure there are
 * at least `MIN_ITEMS` (5) number of items to populate the 5 transition states:
 *
 * 1. Prev Queue: `activeIndex - 2`
 * 2. Prev: `activeIndex - 1`
 * 3. Active: `activeIndex`
 * 4. Next: `activeIndex + 1`
 * 5. Next Queue: `activeIndex + 2`
 *
 * Plus some extras to seamlessly loop through the carousel.
 */
function getMinItems(items: TestimonialsProps['testimonials'], min: number) {
  const itemsCopy = [...items];
  const diff = min - items.length;

  /**
   * Make sure the last `itemsCopy` item matches the last item in `items` for
   * a seamless loop
   */
  while (itemsCopy.length < min) {
    itemsCopy.push(...items.slice(0, min - diff));
  }

  return itemsCopy;
}

/**
 * Return a number within bounds of 0 and `max`
 *
 * Used to cycle through indexes, keeping them within bounds of an array. `max`
 * being the length of the array.
 */
function toRange(value: number, max: number) {
  const index = value % max;
  return index < 0 ? index + max : index;
}

/**
 * Determine which transition state each testimonial is in based on it's index.
 * Used to apply styles to the image and text as the user cycles through the
 * carousel
 */
function getTransitionStates(
  activeIndex: number,
  index: number,
  max: number,
  isLooping: boolean,
) {
  const isActive = activeIndex === index;
  const minus1 = activeIndex - 1;
  const plus1 = activeIndex + 1;

  if (isLooping) {
    return {
      isPrevQueue: toRange(activeIndex - 2, max) === index,
      isPrev: toRange(minus1, max) === index,
      isActive,
      isNext: toRange(plus1, max) === index,
      isNextQueue: toRange(activeIndex + 2, max) === index,
    };
  }

  return {
    isPrevQueue: false,
    isPrev: minus1 === index,
    isActive,
    isNext: plus1 === index,
    isNextQueue: index > plus1,
  };
}

/**
 * Multiple `<Testimonial />` components in a carousel with controls
 */
export default function Testimonials({
  className,
  counterMs = 3000,
  isAutoPlaying = true,
  isLooping = false,
  mode = 'light',
  testimonials: items, // Future rename in types to `items` incoming
  isFullSpan,
}: TestimonialsProps) {
  const MIN_ITEMS = 5;
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const {t} = useTranslations();

  const minItems =
    !isLooping || items.length >= MIN_ITEMS
      ? items
      : getMinItems(items, MIN_ITEMS);
  const minItemsLength = minItems.length;
  const isEnd = activeIndex === minItemsLength - 1;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const componentName = 'testimonials';
  const isReducedMotion = useReducedMotion();

  /**
   * Intersection Observer logic to determine if the carousel is in view
   * if so, play the carousel. If not, pause the carousel.
   */
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        /**
         * Exit early if user prefers reduced motion and carousel is not playing
         *
         * Handles edge case where prefers reduced motion users have set the
         * carousel to play, we should still set `isPlaying` to `false` if the
         * `<Testimonials>` ref is off-screen.
         */
        if (isReducedMotion && !isPlaying) return;

        if (entry.isIntersecting && !isIntersecting) {
          /**
           * Only start playing again if auto playing and not at the end
           */
          if (isAutoPlaying && !isEnd) {
            setIsPlaying(true);
          }

          setIsIntersecting(true);
        }

        if (!entry.isIntersecting && isIntersecting) {
          setIsPlaying(false);
          setIsIntersecting(false);
        }
      });
    },
    [isAutoPlaying, isIntersecting, isEnd, isPlaying, isReducedMotion],
  );

  type ObserveProps = {
    refObj: typeof wrapperRef;
    cb: typeof observerCallback;
  };

  /**
   * Renderless component used to conditionally call `useIntersectionObserver`
   * because we don't need to observe anything if `isReducedMotion`. Unless a
   * prefers reduced motion user has set the carousel to play, in that case
   * we need to observe. See JSX for condition controlling execution.
   */
  function Observe({refObj, cb}: ObserveProps) {
    useIntersectionObserver(refObj, cb);
    return null;
  }

  /**
   * If playing, set a timer to increment the carousel after `counterMs`,
   * cancels the timer in cleanup, which occurs on re-render and unmount
   */
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (isPlaying) {
      timer = setTimeout(() => {
        setActiveIndex((currIndex) => toRange(currIndex + 1, minItemsLength));
      }, counterMs);
    }

    return () => clearTimeout(timer);
  }, [counterMs, isPlaying, activeIndex, minItemsLength]);

  /**
   * Handle reaching the end of the carousel, when not looping
   */
  useEffect(() => {
    if (!isLooping && isEnd) {
      setIsPlaying(false);
    }
  }, [isLooping, isEnd]);

  return (
    <div
      ref={wrapperRef}
      className={twMerge(styles({mode}), className)}
      data-mode={mode}
      data-component-name={componentName}
      role="region"
      aria-roledescription="carousel"
      aria-label={t('global:ariaLabels.testimonial.plural')}
      id="testimonials-container"
    >
      {(isPlaying || !isReducedMotion) && (
        <Observe refObj={wrapperRef} cb={observerCallback} />
      )}

      <DirectionalControls
        aria-controls="testimonials-container"
        activeIndex={activeIndex}
        testimonials={minItems}
        setActiveIndex={setActiveIndex}
        setIsPlaying={setIsPlaying}
        isLooping={isLooping}
        isPlaying={isPlaying}
        mode={mode}
      />

      {minItems.map((testimonial, index) => {
        const {isPrevQueue, isPrev, isActive, isNext, isNextQueue} =
          getTransitionStates(activeIndex, index, minItemsLength, isLooping);

        const ariaLabel = replacePlaceholders(
          t('global:ariaLabels.carousel.xOfY'),
          index + 1,
          items?.length,
        );

        return (
          <Testimonial
            className={testimonialStyles({isActive})}
            style={{transform: `translateX(${index * -100}%)`}}
            key={index}
            mode={mode}
            isFullSpan={testimonial.image === undefined && isFullSpan}
            isActive={isActive}
            ariaHidden={index !== activeIndex}
            ariaLabel={ariaLabel}
            quoteHtml={testimonial.quoteHtml}
            author={testimonial.author}
            authorTitle={testimonial.authorTitle}
            brand={testimonial.brand}
            link={testimonial.link}
            size={testimonial.size}
            image={
              testimonial.image && {
                ...testimonial.image,
                // Ensure next images are loaded so they peek in to the right
                loading: `${isNext || isNextQueue ? 'eager' : 'lazy'}`,
                pictureClassName: twMerge(
                  pictureStyles({
                    isLooping,
                    isPlaying,
                    isActive,
                    isPrev,
                    isPrevQueue,
                    isNext,
                    isNextQueue,
                  }),
                  testimonial.image.pictureClassName,
                ),
              }
            }
          />
        );
      })}
    </div>
  );
}
