import {cva} from 'class-variance-authority';

import {IconEnum} from '@/enums';
import {useTranslations} from '@/hooks/useTranslations';
import InterfaceButton from '@/components/base/elements/InterfaceButton/InterfaceButton';

import type {DirectionalControlsProps} from '../types';

const wrapperStyles = cva([
  'absolute bottom-0 flex items-center space-x-3 md:space-x-4 mt-md z-20',
  /**
   * These `left` values are an unfortunate side effect of needing to
   * absolutely position the controls due to them being outside the grid
   * container of each `<Testimonial />` component and the `<Testimonials>`
   * root container being full screen width to set `overflow-x-clip`
   *
   * `.75rem` is `ml-3`
   * `1420px` is the max-width of `.container`
   */
  'left-[calc(var(--margin)+.75rem)] md:left-margin',
  'xl:left-[calc((100%-1420px)/2)]',
]);

function getNextIndex(activeIndex: number, testimonialsLength: number) {
  return (activeIndex + 1) % testimonialsLength;
}

function getPreviousIndex(activeIndex: number, testimonialsLength: number) {
  return (activeIndex === 0 ? testimonialsLength : activeIndex) - 1;
}

/**
 * The controls for the `<Testimonials />` component
 */
export default function DirectionalControls({
  activeIndex,
  isLooping = false,
  isPlaying,
  mode = 'light',
  setActiveIndex,
  setIsPlaying,
  testimonials,
}: DirectionalControlsProps) {
  const {t} = useTranslations();

  const numberOfTestimonials = testimonials?.length || 0;

  const isFirst = activeIndex === 0;
  const isLast = activeIndex === numberOfTestimonials - 1;

  const handleClickNext = () => {
    setActiveIndex(getNextIndex(activeIndex, numberOfTestimonials));
  };

  const handleClickPrev = () => {
    setActiveIndex(getPreviousIndex(activeIndex, numberOfTestimonials));
  };

  return (
    <div
      className={wrapperStyles()}
      role="group"
      aria-label={t('global:ariaLabels.testimonial.controls')}
    >
      {/* Previous Button */}
      <InterfaceButton
        ariaLabel={t('global:ariaLabels.testimonial.previous')}
        isDisabled={!isLooping && isFirst}
        iconProps={{
          icon: IconEnum.Previous,
        }}
        onClick={handleClickPrev}
        mode={mode}
      />
      {/* Play/Pause Button */}
      <InterfaceButton
        ariaLabel={
          isPlaying
            ? t('global:ariaLabels.testimonial.pause')
            : t('global:ariaLabels.testimonial.play')
        }
        onClick={() => {
          setIsPlaying(!isPlaying);
        }}
        iconProps={{
          icon: IconEnum.PlayPause,
          options: {isPlaying},
        }}
        isDisabled={!isLooping && isLast}
        mode={mode}
      />
      {/* Next Button */}
      <InterfaceButton
        ariaLabel={t('global:ariaLabels.testimonial.next')}
        isDisabled={!isLooping && isLast}
        iconProps={{
          icon: IconEnum.Next,
        }}
        onClick={handleClickNext}
        mode={mode}
      />
    </div>
  );
}
